import React from 'react'
// import { GiNotebook, GiVote } from 'react-icons/gi';
import styled, { keyframes } from 'styled-components'


// import { NavLink } from 'react-router-dom'
import TranslatedText from '../TranslatedText'
//const iconexchange = require('../../assets/images/entry-icon-swap.svg');
// const iconfarming = require('../../assets/images/entry-icon-farms.svg');
// const iconstake = require('../../assets/images/entry-icon-staking.svg');
//const iconpool = require('../../assets/images/icon-pools.svg');
//const iconliquidity = require('../../assets/images/icon-liquidity.svg');
const logoDark = require('../../assets/images/dotbloxnew.png')
// import LngSwith from '../Header/LngSwith'
// import useHTPrice from '../../hooks/useHtPrice'
// import { useActiveWeb3React } from '../../hooks'
// import TopDecoration from './TopDecoration'
// import BottomDecoration from './BottomDecoration'

interface MobileMenuProps {
  onDismiss: () => void
  visible?: boolean
}

// function isZero(num: number) {
//   return Math.abs(num - 0) <= 1e-8
// }

// eslint-disable-next-line react/prop-types
const MobileMenu: React.FC<MobileMenuProps> = ({ onDismiss, visible }) => {
  // const { pippiPrice } = useHTPrice()
  // const { account } = useActiveWeb3React()
  if (visible) {
    return (
      <StyledMobileMenuWrapper>
        <StyledBackdrop onClick={onDismiss} />
        <StyledMobileMenu>
          <Bg></Bg>
          {/* <TopDecoration /> */}
          {/* <BottomDecoration /> */}
          <Cn>

            <img src={logoDark} style={{ height: "20px" }} />
            {/* <TranslatedText translationId={130}>Calculas</TranslatedText> */}

          </Cn>

          {/* <Cn>
            <StyledAbsoluteLink href="https://puddingswap.finance">
              <img src={iconstake} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} />
              <TranslatedText translationId={130}>Home</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}
          {/* <Cn>
            <StyledLink className="" to="#">
            <img src={iconexchange} style={{height:"20px", width:"30px" ,position: 'relative', top: '4px'}} />
            <TranslatedText translationId={202}>Exchange</TranslatedText>
            </StyledLink>
          </Cn> */}

          <Cn>
            <StyledAbsoluteLink href="#swap">
              {/* <img src={iconexchange} style={{ height: "20px", width: "30px", position: 'relative', top: '4px' }} /> */}
              <TranslatedText translationId={200}>Swap</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>

          <Cn>
            <StyledAbsoluteLink href="#add/E">
              {/* <img src={iconliquidity} style={{ height: "20px", width: "30px", position: 'relative', top: '4px' }} /> */}
              <TranslatedText translationId={198}>Liquidity</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>


          <Cn>
            <StyledAbsoluteLink href="#pool">
              {/* <img src={iconpool} style={{ height: "20px", width: "30px", position: 'relative', top: '4px' }} /> */}
              <TranslatedText translationId={198}>Pools</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>
          <Cn>
            <StyledBordrSpace >
              <div className='w-100'></div>
              {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} />
        <TranslatedText translationId={200}>Pools</TranslatedText> */}
            </StyledBordrSpace>
          </Cn>
          <Cn>
          <StyledAbsoluteLink href="https://explorer.dotblox.io/" target="_blank">
            {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
            <TranslatedText translationId={200}>Explorer</TranslatedText>
          </StyledAbsoluteLink>
          </Cn>
          <Cn>
          <StyledAbsoluteLink href="https://staking.dotblox.io/" target="_blank">
            {/* <img src={iconstake} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
            <TranslatedText translationId={200}>Staking</TranslatedText>
          </StyledAbsoluteLink>
          </Cn>
          <Cn>
          <StyledAbsoluteLink href="https://xtring.network/" target="_blank">
            {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
            <TranslatedText translationId={200}>Crosschain</TranslatedText>
          </StyledAbsoluteLink>
          </Cn>
          <Cn>
          <StyledAbsoluteLink href="https://createtoken.dotblox.io/" target="_blank">
            {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
            <TranslatedText translationId={200}>Create Token</TranslatedText>
          </StyledAbsoluteLink>
          </Cn>
          <Cn>
          <StyledAbsoluteLink href="https://dtbx.exchange/" target="_blank">
            {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
            <TranslatedText translationId={200}>Exchange</TranslatedText>
          </StyledAbsoluteLink>
          </Cn>
          <Cn>
          <StyledAbsoluteLink href="https://status.dotblox.io/" target="_blank">
            {/* <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> */}
            <TranslatedText translationId={200}>Status</TranslatedText>
          </StyledAbsoluteLink>
          </Cn>

          {/* <Cn>
            <StyledAbsoluteLink href="#lp-farm">
              <img src={iconfarming} style={{ height: "20px", width: "30px", position: 'relative', top: '4px' }} />
              <TranslatedText translationId={200}>LP Farm</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>
          <Cn>
            <StyledAbsoluteLink href="#staking">
              <img src={iconfarming} style={{ height: "20px", width: "30px", position: 'relative', top: '4px' }} />
              <TranslatedText translationId={200}>Staking</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}

          {/* <Cn>
            <StyledAbsoluteLink href="#">
              <img src={iconstake} style={{ height: "20px", width: "30px", position: 'relative', top: '4px' }} />
              <TranslatedText translationId={200}>Vault</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}

          {/* <Cn>
            <StyledAbsoluteLink href="#voting">
              {/* <img src={iconstake} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> *
              <GiVote style={{ height: "20px", width: "30px", position: 'relative', top: '4px', color: "#2c99ac" }} />
              <TranslatedText translationId={200}>Voting</TranslatedText>
            </StyledAbsoluteLink>
            </Cn>
            
            <Cn>
            <StyledAbsoluteLink href="#proposal">
              {/* <img src={iconstake} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} /> *
              <GiNotebook style={{ height: "20px", width: "30px", position: 'relative', top: '4px', color: "#2c99ac" }} />
              <TranslatedText translationId={200}>Proposal</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}

          {/* <Cn>
            <StyledAbsoluteLink href="https://puddingswap.finance/pudvault">
              <TranslatedText translationId="nav-pud-vault">LockVault</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>
          <Cn>
            <StyledAbsoluteLink href="https://puddingswap.finance/ePUD">
              <TranslatedText translationId={218}>ePUD Pools</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>
          <Cn>
            <StyledAbsoluteLink href="https://puddingswap.finance/chefnft">NFT</StyledAbsoluteLink>
          </Cn> */}

          {/* <Cn>
            <StyledAbsoluteLink href="https://puddingswap.finance/ido">IDO</StyledAbsoluteLink>
          </Cn>
          <Cn>
            <StyledAbsoluteLink href="https://info.puddingswap.finance">
              <TranslatedText translationId={348}>Analytics</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>
          <Cn>
            <StyledAbsoluteLink href="https://voting.puddingswap.finance">
              <TranslatedText translationId={370}>Voting</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>
          <Bottom>
            {account && !isZero(pippiPrice) && <Price className="number">1PUD=${pippiPrice.toFixed(3)}</Price>}
            <LngSwith className="mobile-lng-swith"></LngSwith>
          </Bottom> */}
        </StyledMobileMenu>
      </StyledMobileMenuWrapper>
    )
  }
  return null
}

// const Bottom = styled.div`
//   position: absolute;
//   left: 0;
//   z-index: 1000;
//   width: 80%;
//   bottom: 120px;
//   right: 0;
//   margin: auto;
//   text-align: center;
//   .mobile-lng-swith {
//     width: 100%;
//     height: 30px;
//     line-height: 30px;
//     margin-bottom: 20px;
//   }
// `

const Bg = styled.div`
  position: absolute;
  right: 0;
  width: 70%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
`
const StyledBackdrop = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  //background-color: ${props => props.theme.colors.bg5};
  // background-color:rgb(43 12 69 / 58%);
  backdrop-filter: blur(3px);
  overflow:hidden;
`

const StyledMobileMenuWrapper = styled.div`
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
`

const slideIn = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(100%);
  }
`

const StyledMobileMenu = styled.div`
  animation: ${slideIn} 0.3s forwards ease-out;
  //background-color: ${props => props.theme.colors.bg1};
  //border-left: 1px solid ${props => props.theme.colors.bg1};
  // background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
  background: #19191c;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  width: 180px;
  padding-top: 20px; 
  box-shadow: 0px 5px 70px rgb(18 15 21);

`
const StyledBordrSpace = styled.div`

  position: relative;
  border: 1px solid #00000017;
  width:100%;
  // color: #fff;
  // // margin-right: 24px;
  // text-decoration: none;
  &:hover {
    //color: ${({ theme }) => theme.colors.primary};
    background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
    color:#fff;
  }
  @media (max-width: 400px) {
    margin-right: 24px;
  }
`
// const StyledLink = styled(NavLink)`
//   position: relative;
//   margin: 2px;
//   padding: 8px;
//   box-sizing: border-box;
//   color: rgb(127, 134, 143);
//   font-size: 16px;
//   font-weight: 700;
//   text-align: left;
//   text-decoration: none;
//   &.active {
//     color: #39d0d8;
//   }

// `
const StyledAbsoluteLink = styled.a`
  position: relative;
  display: block;  
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  color: #39d0d8;
  margin: 2px;
  padding: 8px;
  border-radius: 0.25rem;
  text-decoration: none;
  transition: 1s;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    // background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
    background: #14131329;
        color:#fff;
  }
  &.active {
    // background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
    background: #14131329;
    color:#fff;
  }
`
const Cn = styled.div`
  margin: 24px 24px 0 14px;
`
// const Price = styled.div`
//   width: 100%;
//   padding-top: 22px;
//   padding-bottom: 10px;
//   color: ${props => props.theme.colors.primary};
//   text-align: left;
//   font-weight: bolder;
// `
export default MobileMenu
